import React from "react";
import Resizer from "react-image-file-resizer";
import { BaseUrl } from "../../../BaseUrl";
import "./Upload.css";
import { RiCheckboxCircleFill, RiImageAddLine } from "react-icons/ri";
import Loading from "./Loading";
import { useState } from "react";
import { connect } from "react-redux";
import { setFile } from "../../../Actions/AppActions";
const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1024,
      1024,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });
const dataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
  return new Blob([ia], { type: mimeString });
};

function ImageUpload({ onImage, values, currentPage }) {
  const [loading, setLoading] = useState(false);
  const onChange = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    if (file) {
      const image = await resizeFile(file);
      const newFile = dataURIToBlob(image);
      const formData = new FormData();
      formData.append("avatar", newFile);
      const res = await fetch(`${BaseUrl}${currentPage.path}photo`, {
        method: "POST",
        body: formData,
      });
      const data = await res.text();
      onImage({ target: { name: "image", value: data } });
    }
    setLoading(false);
  };
  return (
    <>
      <label for="image-upload" class="custom-file-upload">
        <div style={{ gridGap: "10px", display: "flex", alignItems: "center" }}>
          <RiImageAddLine></RiImageAddLine>

          {values.image ? values.image : "Rasm yuklash"}
        </div>

        {loading ? (
          <Loading></Loading>
        ) : (
          values.image && (
            <RiCheckboxCircleFill
              style={{ color: "green", fontSize: "16px" }}
            ></RiCheckboxCircleFill>
          )
        )}
      </label>
      <input onChange={onChange} id="image-upload" type="file" />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    values: state.app.values,
    currentPage: state.app.currentPage,
  };
};
export default connect(mapStateToProps, {})(ImageUpload);
