import React from "react";
import ModalForm from "./ModalForm";
import { connect } from "react-redux";
import {
  setFile,
  setModal,
  setRowId,
  setValues,
} from "../../Actions/AppActions";
import "./Modal.css";
import { useHistory } from "react-router";
import Modal from "antd/lib/modal/Modal";
import { RiCloseCircleFill } from "react-icons/ri";
import InnerModal from "./InnerModal";
import SecondaryModal from "./SecondaryModal";
function ModalExampleModal({
  setModal,
  modal,
  setValues,
  setRowId,
  currentPage,
  setFile,
}) {
  const clearModal = () => {
    setModal(false);
    setRowId(null);
    setValues({});
    setFile("");
  };
  return (
    <Modal
      centered
      maskClosable={false}
      keyboard={false}
      title={
        <div className="modal-title">
          <span>{currentPage.title}</span>
        </div>
      }
      width={currentPage.modalWidth ? currentPage.modalWidth : "100%"}
      onCancel={clearModal}
      visible={modal}
      footer={null}
      closeIcon={<RiCloseCircleFill />}
    >
      <ModalForm clearModal={clearModal}></ModalForm>
      <InnerModal></InnerModal>
      <SecondaryModal></SecondaryModal>
    </Modal>
  );
}
const mapStateToProps = (state) => {
  return {
    modal: state.app.modal,
    currentPage: state.app.currentPage,
  };
};
export default connect(mapStateToProps, {
  setModal,
  setValues,
  setRowId,
  setFile,
})(ModalExampleModal);
