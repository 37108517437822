import { Button, message } from "antd";
import React from "react";
import { RiAddCircleFill, RiDeleteBinFill } from "react-icons/ri";
import { connect } from "react-redux";
import { deleteDocument, setInnerModal } from "../../../Actions/AppActions";
import ModalInputs from "../../Modals/ModalInputs";

const ModalTableActions = ({
  setInnerModal,
  rowId,
  deleteDocument,
  currentPage,
}) => {
  const openAdd = () => {
    setInnerModal(true);
  };
  const handleDelete = () => {
    if (rowId) {
      deleteDocument(rowId);
    } else {
      message.error("Qatorni tanlang!");
    }
  };
  let a = [];
  if (currentPage.forms.length > 0 && currentPage.forms[0].type == "document") {
    a = currentPage.forms[0].actionInputs;
  }
  return (
    <div style={{ display: "flex", gridGap: "10px", alignItems: "flex-end" }}>
      <Button onClick={openAdd} type="primary" className="action_btn main-btn">
        <RiAddCircleFill style={{ fontSize: "18px" }}></RiAddCircleFill>
      </Button>

      <Button onClick={handleDelete} className="action_btn delete-btn">
        <RiDeleteBinFill style={{ fontSize: "18px" }}></RiDeleteBinFill>
      </Button>
      {a.length > 0 && <ModalInputs forms={a}></ModalInputs>}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    rowId: state.app.rowId,
    currentPage: state.app.currentPage,
  };
};
export default connect(mapStateToProps, { setInnerModal, deleteDocument })(
  ModalTableActions
);
