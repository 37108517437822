import React, {useState} from "react";
import {RiAddLine, RiCheckFill, RiCloseCircleLine, RiCloseLine, RiPencilLine,} from "react-icons/ri";
import {Button, message, Modal, Popconfirm} from "antd";
import "./Actions.css";
import {getAllData, getMainTableData, setModal, setModal2, setRowId, setValues,} from "../../Actions/AppActions";
import {connect, useSelector} from "react-redux";
import fetchApi from "../../Functions/fetchApi";
import Excel from "../../Functions/Excel";
import {useHistory} from "react-router-dom";
import AllMapContainer from "../../Pages/ServicePages/components/AllMapContainer";

const Actions = ({
                     setModal,
                     setModal2,
                     rowId,
                     data,
                     setValues,
                     currentPage,
                     getMainTableData,
                     allData,
                     getAllData,
                     setRowId,
                 }) => {
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState({
        pop1: false,
        pop2: false,
        pop3: false,
    });
    const openAdd = () => {
        if (currentPage.defaultValues && currentPage.defaultValues.length > 0) {
            let obj = {};
            currentPage.defaultValues.forEach((d) => {
                obj[d.prop] = d.value;
            });
            setValues(obj);
        }
        console.log(currentPage.allData);
        getAllData(currentPage.allData.map((al) => al.prop));
        setModal(true);
    };

    const {location} = useHistory();
    const openEdit = () => {
        if (rowId) {
            let obj = data.find((d) => d.Id === rowId);
            setValues(obj);
            setModal(true);
        } else {
            message.warning("Qatorni tanlang!");
        }
    };
    const handleDelete = async () => {
        let user_id = null;
        if (
            JSON.parse(window.sessionStorage.getItem("user")) &&
            JSON.parse(window.sessionStorage.getItem("user")).id
        ) {
            user_id = JSON.parse(window.sessionStorage.getItem("user")).id;
        }
        if (rowId) {
            setLoading(true);
            const res = await fetchApi(currentPage.path + "/" + rowId, "DELETE", {
                user_id: user_id,
            });
            if (res && res.status === 200) {
                setRowId(null);
                getMainTableData(currentPage.path);
            }
            setVisible({
                ...visible,
                pop1: false,
                pop3: false,
                pop2: false,
            });
            setLoading(false);
        } else {
            message.warning("Qatorni tanlang!");
        }
    };

    const handleTugatildi = async () => {
        let user_id = null;
        if (
            JSON.parse(window.sessionStorage.getItem("user")) &&
            JSON.parse(window.sessionStorage.getItem("user")).id
        ) {
            user_id = JSON.parse(window.sessionStorage.getItem("user")).id;
        }
        if (rowId) {
            let obj = data.find((d) => d.Id === rowId);
            setLoading(true);
            const res = await fetchApi(currentPage.path + "/t/" + rowId, "POST", obj);
            if (res && res.status === 200) {
                setRowId(null);
                getMainTableData(currentPage.path);
            }
            setVisible({
                ...visible,
                pop1: false,
                pop3: false,
                pop2: false,
            });
            setLoading(false);
        } else {
            message.warning("Qatorni tanlang!");
        }
    };

    const handleIshgaTushdi = async () => {
        let user_id = null;
        if (
            JSON.parse(window.sessionStorage.getItem("user")) &&
            JSON.parse(window.sessionStorage.getItem("user")).id
        ) {
            user_id = JSON.parse(window.sessionStorage.getItem("user")).id;
        }
        if (rowId) {
            let obj = data.find((d) => d.Id === rowId);
            setLoading(true);
            const res = await fetchApi(currentPage.path + "/" + rowId, "POST", obj);
            if (res && res.status === 200) {
                setRowId(null);
                getMainTableData(currentPage.path);
            }
            setVisible({
                ...visible,
                pop1: false,
                pop3: false,
                pop2: false,
            });
            setLoading(false);
        } else {
            message.warning("Qatorni tanlang!");
        }
    };

    const handleBekorQilindi = async () => {
        let user_id = null;
        if (
            JSON.parse(window.sessionStorage.getItem("user")) &&
            JSON.parse(window.sessionStorage.getItem("user")).id
        ) {
            user_id = JSON.parse(window.sessionStorage.getItem("user")).id;
        }
        if (rowId) {
            setLoading(true);
            let obj = data.find((d) => d.Id === rowId);
            const res = await fetchApi(currentPage.path + "/a/" + rowId, "POST", obj);
            if (res && res.status === 200) {
                setRowId(null);
                getMainTableData(currentPage.path);
            }
            setVisible({
                ...visible,
                pop1: false,
                pop3: false,
                pop2: false,
            });
            setLoading(false);
        } else {
            message.warning("Qatorni tanlang!");
        }
    };

    const toExcel = () => {
        const mapedData = data.map((d) => {
            let a = {};
            currentPage.allData.forEach((obj) => {
                if (allData[obj.prop]?.find((k) => k.Id == d[obj.value])) {
                    a[obj.prop] = allData[obj.prop]?.find((k) => k.Id == d[obj.value])[
                        obj.get
                        ];
                    delete d[obj.value];
                }
            });
            if (d.hasOwnProperty("valyuta_turi")) {
                d.valyuta_turi = d.valyuta_turi == 1 ? "Валюта" : "Сўм";
            }
            if (d.hasOwnProperty("turi")) {
                d.turi = d.turi == 3 ? "Мижоз" : d.turi == 1 ? "Агент" : "Доставчик";
            }
            return {...d, ...a};
        });
        Excel(mapedData);
    };
    const history = useHistory();
    const toPrint = () => {
        if (rowId) {
            history.push("/print");
        }
    };

    const {app: {mainTableData}} = useSelector(s => s);
    const [isModal, setIsModal] = useState(false);

    function toggleModal() {
        setIsModal(p => !p)
    }

    return (
        <div style={{display: "flex"}} className="body_actions">
            {
                location.pathname !== "/yangimijozlar" ?
                    <>
                        <Button onClick={openAdd} type="primary" className="action_btn main-btn">
                            <RiAddLine className="action_icon"/> &nbsp;{" "}
                            <span className="action_text">Qo'shish</span>
                        </Button>
                        &nbsp; &nbsp;
                        <Button onClick={openEdit} type="primary" className="action_btn edit-btn">
                            <RiPencilLine className="action_icon"/> &nbsp;{" "}
                            <span className="action_text">O'zgartirish</span>
                        </Button>
                        &nbsp; &nbsp;
                        <Popconfirm title="Ushbu dasturni o'chirmoqchimisiz?"
                                    visible={visible.pop1}
                                    onConfirm={handleDelete}
                                    okButtonProps={{loading: loading}}
                                    onCancel={() =>
                                        setVisible({
                                            ...visible,
                                            pop1: false,
                                            pop3: false,
                                            pop2: false,
                                        })}>
                            <Button icon
                                    loading={loading}
                                    className="action_btn delete-btn"
                                    onClick={() => {
                                        if (rowId) {
                                            setVisible({
                                                ...visible,
                                                pop3: false,
                                                pop2: false,
                                                pop1: true,
                                            });
                                        } else message.warning("Qatorni tanlang!");
                                    }}
                            >
                                <RiCloseLine className="action_icon"/> &nbsp;{" "}
                                <span className="action_text">O'chirish</span>
                            </Button>
                        </Popconfirm>
                    </> : ""
            }
            {
                location.pathname === "/yangimijozlar" ? <Popconfirm
                    title="Ushbu dasturni o'chirmoqchimisiz?"
                    visible={visible.pop1}
                    onConfirm={handleDelete}
                    okButtonProps={{loading: loading}}
                    onCancel={() =>
                        setVisible({
                            ...visible,
                            pop1: false,
                            pop3: false,
                            pop2: false,
                        })
                    }
                >
                    <Button
                        icon
                        loading={loading}
                        className="action_btn delete-btn"
                        style={{marginLeft: "10px"}}
                        // onClick={() => console.log("dsfdsfds")}
                        onClick={() => {
                            toggleModal();
                        }}
                    >
                        {/*<RiCloseLine className="action_icon"></RiCloseLine> &nbsp;{" "}*/}
                        <span className="action_text">Barcha mijozlar haritada</span>
                    </Button>
                    <Modal className={"mapModal"} title="All user location" visible={isModal} footer={null}
                           onCancel={toggleModal} onOk={toggleModal}>
                        <AllMapContainer data={data}/>
                    </Modal>
                </Popconfirm> : ""}
            {/*------------------------------------------------------------------------------------------------*/}
            {/* &nbsp; &nbsp;
      <Button onClick={toExcel} type="primary" className="action_btn main-btn">
        <RiFileExcel2Fill className="action_icon"></RiFileExcel2Fill> &nbsp;{" "}
        <span className="action_text">Excelga olish</span>
      </Button> */}
            &nbsp; &nbsp;
            {(location.pathname == "/dasturlar/1" ||
                location.pathname == "/dasturlar/4") && (
                <>
                    <Popconfirm
                        title="Ushbu dastur ishga tushdimi?"
                        visible={visible.pop2}
                        onConfirm={handleIshgaTushdi}
                        okButtonProps={{loading: loading}}
                        onCancel={() =>
                            setVisible({
                                ...visible,
                                pop2: false,
                                pop1: false,
                                pop3: false,
                            })
                        }
                    >
                        <Button
                            onClick={() => {
                                if (rowId) {
                                    setVisible({
                                        ...visible,
                                        pop1: false,
                                        pop3: false,
                                        pop2: true,
                                    });
                                } else message.warning("Qatorni tanlang!");
                            }}
                            type="primary"
                            className="action_btn main-btn"
                        >
                            <RiCheckFill className="action_icon"></RiCheckFill> &nbsp;{" "}
                            <span className="action_text">Ishga tushdi</span>
                        </Button>
                    </Popconfirm>
                    &nbsp; &nbsp;
                </>
            )}
            {(location.pathname == "/dasturlar/2" ||
                location.pathname == "/dasturlar/4") && (
                <>
                    <Popconfirm
                        title="Ushbu dastur tugatildimi?"
                        visible={visible.pop2}
                        onConfirm={handleTugatildi}
                        okButtonProps={{loading: loading}}
                        onCancel={() =>
                            setVisible({
                                ...visible,
                                pop2: false,
                                pop1: false,
                                pop3: false,
                            })
                        }
                    >
                        <Button
                            onClick={() => {
                                if (rowId) {
                                    setVisible({
                                        ...visible,
                                        pop1: false,
                                        pop3: false,
                                        pop2: true,
                                    });
                                } else message.warning("Qatorni tanlang!");
                            }}
                            type="primary"
                            className="action_btn main-btn"
                        >
                            <RiCheckFill className="action_icon"></RiCheckFill> &nbsp;{" "}
                            <span className="action_text">Tugatildi</span>
                        </Button>
                    </Popconfirm>
                    &nbsp; &nbsp;
                </>
            )}
            {(location.pathname == "/dasturlar/1" ||
                location.pathname == "/dasturlar/2") && (
                <>
                    <Popconfirm
                        title="Ushbu dastur bekor qilindimi?"
                        visible={visible.pop3}
                        onConfirm={handleBekorQilindi}
                        okButtonProps={{loading: loading}}
                        onCancel={() =>
                            setVisible({
                                ...visible,
                                pop1: false,
                                pop2: false,
                                pop3: false,
                            })
                        }
                    >
                        <Button
                            onClick={() => {
                                if (rowId) {
                                    setVisible({
                                        ...visible,
                                        pop1: false,
                                        pop2: false,
                                        pop3: true,
                                    });
                                } else message.warning("Qatorni tanlang!");
                            }}
                            type="primary"
                            className="action_btn delete-btn"
                        >
                            <RiCloseCircleLine className="action_icon"></RiCloseCircleLine>{" "}
                            &nbsp; <span className="action_text">Bekor qilish</span>
                        </Button>
                    </Popconfirm>
                </>
            )}
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        rowId: state.app.rowId,
        data: state.app.mainTableData,
        loading: state.app.loading,
        values: state.app.values,
        allData: state.app.allData,
        currentPage: state.app.currentPage,
    };
};

export default connect(mapStateToProps, {
    setModal,
    getMainTableData,
    setValues,
    setModal2,
    getAllData,
    setRowId,
})(Actions);
