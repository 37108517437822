import React, { useState } from "react";
import { connect } from "react-redux";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import moment from "moment";
import { RiBattery2ChargeFill } from "react-icons/ri";
import { Input } from "antd";
import { setModal, setValues } from "../../Actions/AppActions";

const MapController = ({ values, setValues }) => {
  const lat = values.lat || 40.39985657929619;
  const lang = values.lang || 71.78690340662425;
  const [map, setMap] = useState(null);

  if (map && lat && lang) {
    map.flyTo([lat, lang]);
  }
  const changeCoordinates = (e) => {
    // setValues({ [e.target.name]: e.target.value });
  };
  function ChangeMapView({ coords }) {
    const map = useMap();
    // map.setView({ lng: values?.lat }, map.getZoom());

    return null;
  }
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gridGap: "20px",
      }}
    >
      <div>
        <label>Latitude</label>
        <Input
          id="lat"
          name="latitude"
          value={values?.latitude}
          onChange={changeCoordinates}
        />
        <h1></h1>
        <h1></h1>
        <label>Longitude</label>
        <Input id="lang" name="longitude" value={values?.longitude} />
      </div>

      <MapContainer
        whenCreated={setMap}
        center={[lat, lang]}
        style={{ height: "200px", width: "100wh", borderRadius: "10px" }}
        zoom={15}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {lat == "40.360682" ? null : (
          <Marker position={[lat, lang]}>
            <Popup>
              нинг<br></br> {moment().format("DD.MM.YYYY HH:mm")}
              <br></br>
              пайтдаги жойлашуви <br></br>
              <RiBattery2ChargeFill
                style={{ color: "var(--main-color)", verticalAlign: "middle" }}
              ></RiBattery2ChargeFill>
              -50%
            </Popup>
          </Marker>
        )}

        <ChangeMapView coords={{ lng: values.lng }} />
      </MapContainer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    rowId: state.app.rowId,
    values: state.app.values,
    allData: state.app.allData,
    loading: state.app.loading,
    columns: state.app.currentPage.columns,
    currentPage: state.app.currentPage,
    modal: state.app.modal,
    mainTableData: state.app.mainTableData,
  };
};
export default connect(mapStateToProps, {
  setModal,
  setValues,
})(MapController);
