import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import MainTable from "../MainTable";
import ModalTableActions from "./ModalTableActions";

const ModalTable = ({
  columns,
  document,
  innerTable,
  innerTableData,
  innerModal,
}) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (innerTable) {
      setData([...innerTableData]);
    } else {
      setData([...document]);
    }
  }, [innerModal, document, innerTableData]);
  return (
    <div>
      {innerTable ? null : <ModalTableActions></ModalTableActions>}
      <MainTable
        innerTable={innerTable}
        costumColumns={columns}
        data={data}
      ></MainTable>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    document: state.app.values.document ? state.app.values.document : [],
    innerModal: state.app.innerModal,
  };
};
export default connect(mapStateToProps)(ModalTable);
