import {
  COSTUM,
  DATE,
  PHONE,
  SELECT,
  STRING,
  CURRENCY,
  TEXTBLOCK,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import { RiUser3Fill } from "react-icons/ri";
import "react-medium-image-zoom/dist/styles.css";
import { SIMPLE } from "../../Component/Sidebar/PageTypes";
import { tolovTurlari } from "../../Functions/Constanta";
import DocumentClass from "../../Functions/DocumentClass";
import moment from "moment";
import SanaFormat from "../../Functions/SanaFormat";
const DasturTolovi = {
  title: "Dastur to'lovi",
  path: "/dasturTolovi",
  icon: <RiUser3Fill></RiUser3Fill>,
  type: SIMPLE,
  allData: [
    { value: "user_id", prop: "users", get: "fio" },
    { value: "dastur_id", prop: "dastur_qoldiq", get: "dastur_nomi" },
  ],
  filters: [
    {
      dataIndex: "dastur_qoldiq",
    },
  ],
  columns: [
    {
      title: <div className="table_head">№</div>,
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">Vaqti</div>,
      dataIndex: "time_create",
      width: "80px",
      render: (text) => SanaFormat(text),
    },
    {
      title: <div className="table_head">Dastur nomi</div>,
      dataIndex: "dastur_qoldiq",
      onFilter: (value, record) => record.dastur_qoldiq?.indexOf(value) === 0,
      width: "120px",
    },
    {
      title: <div className="table_head">To'lov turi</div>,
      dataIndex: "tolov_turi",
      key: "tolov_turi",
      width: "150px",
      render: (text) => {
        return tolovTurlari[text];
      },
    },
    {
      title: <div className="table_head">To'lov summasi</div>,
      children: [
        {
          title: <div className="table_head">So'm</div>,
          dataIndex: "summa_som",
          width: "79px",
        },
        {
          title: <div className="table_head">Dollar $</div>,
          dataIndex: "summa_val",
          width: "79px",
        },
        {
          title: <div className="table_head">Perech</div>,
          dataIndex: "summa_perech",
          width: "79px",
        },
      ],
    },
    {
      title: <div className="table_head">Izox</div>,
      dataIndex: "izox",
      width: "70px",
    },
    {
      title: <div className="table_head">User FIO</div>,
      dataIndex: "users",
      width: "80px",
    },
  ],

  forms: [
    {
      grid: "150px 250px 70px 60px 60px 60px 70px 60px 60px",
      inputs: [
        {
          label: "Sana",
          name: "sana",
          type: DATE,
        },
        {
          label: "Dastur",
          name: "dastur_id",
          type: SELECT,
          required: true,
          allData: {
            type: "dastur_qoldiq",
            key: "Id",
            text: "dastur_nomi",
          },
        },
        {
          label: "So'm",
          name: "dastur_som",
          type: TEXTBLOCK,
        },
        {
          label: "Dollar $",
          name: "dastur_val",
          type: TEXTBLOCK,
        },
        {
          label: "Perech",
          name: "dastur_perech",
          type: TEXTBLOCK,
        },
        {
          name: "",
          type: COSTUM,
          component: (
            <div
              style={{
                background: "gray",
                color: "white",
                display: "inline-grid",
                height: "30px",
                alignItems: "center",
              }}
            >
              _Qoldiq_
            </div>
          ),
        },
        {
          label: "So'm",
          name: "qoldiq_som",
          type: TEXTBLOCK,
        },
        {
          label: "Dollar $",
          name: "qoldiq_val",
          type: TEXTBLOCK,
        },
        {
          label: "Perech",
          name: "qoldiq_perech",
          type: TEXTBLOCK,
        },
      ],
    },
    {
      grid: "1fr 1fr 1fr 1fr 1fr",
      inputs: [
        {
          label: "To'lov turi",
          name: "tolov_turi",
          type: SELECT,
          required: true,
          costumData: [
            { Id: 1, nomi: tolovTurlari[1] },
            { Id: 2, nomi: tolovTurlari[2] },
            { Id: 3, nomi: tolovTurlari[3] },
            { Id: 4, nomi: tolovTurlari[4] },
            { Id: 5, nomi: tolovTurlari[5] },
            { Id: 6, nomi: tolovTurlari[6] },
            { Id: 7, nomi: tolovTurlari[7] },
            { Id: 8, nomi: tolovTurlari[8] },
            { Id: 9, nomi: tolovTurlari[9] },
          ],
        },

        {
          label: "Dollar kursi",
          name: "kurs",
          type: CURRENCY,
        },
        {
          label: "To'lov so'm",
          name: "summa_som",
          type: CURRENCY,
        },
        {
          label: "To'lov dollar $",
          name: "summa_val",
          type: CURRENCY,
        },
        {
          label: "To'lov perech",
          name: "summa_perech",
          type: CURRENCY,
        },
      ],
    },
    {
      grid: "1fr",
      inputs: [
        {
          label: "Izox",
          name: "izox",
          type: STRING,
        },
      ],
    },
  ],
};
export default DasturTolovi;
