// import { Table } from "ant-table-extensions";
import { Table } from "antd";
import { connect } from "react-redux";
import {
  addDocument,
  setInnerModal,
  setRowId,
  setValues,
  setModal,
} from "../../Actions/AppActions";
import "./Table.css";
import uuid from "react-uuid";
import FilterColumns from "../../Functions/FilterColumns";
import React from "react";
const MainTable = ({
  columns,
  costumColumns,
  data,
  setRowId,
  rowId,
  notX,
  loading,
  summary,
  addDocument,
  innerTable,
  setInnerModal,
  notPag,
  currentPage,
  modal,
  setValues,
  setModal,
  mainTableData,
}) => {
  const openAdd = () => {
    if (currentPage.defaultValues && currentPage.defaultValues.length > 0) {
      let obj = {};
      currentPage.defaultValues.forEach((d) => {
        obj[d.prop] = d.value;
      });
      setValues(obj);
    }
    setModal(true);
  };

  const openAddInnerModal = (record) => {
    // setInnerModal(true);
    let obj = {};
    if (currentPage.forms?.find((f) => f.type == "document")) {
      const uniq = currentPage.forms?.find((f) => f.type == "document")
        .inputs[0].innerModal?.unique;
      if (uniq) {
        obj = { ...record };
      } else {
        if (
          currentPage.path == "/maxsulot-kirimi" ||
          currentPage.path == "/yuk-beruvchi-vozvrat"
        ) {
          var obj1 = {
            kirim_som: record.valyuta_turi == 2 ? record.kirim_narxi : 0,
            kirim_val: record.valyuta_turi == 1 ? record.kirim_narxi : 0,
            sotish_som: record.valyuta_turi == 2 ? record.sotish_narxi : 0,
            sotish_val: record.valyuta_turi == 1 ? record.sotish_narxi : 0,
            zapis: false,
          };
        }
        obj = {
          ...record,
          ...obj1,
          Id: uuid(),
          element_id: record.Id,
        };
      }
    }

    addDocument(obj);
  };

  const handleKeyDown = (event) => {
    // Insert bosilgan bolsa
    if (event.keyCode == 45) {
      if (modal) {
        openAddInnerModal(null);
      } else {
        openAdd();
      }
      console.log("Insert bosildi");
    }
    // past knopka bosilsa
    else if (event.keyCode == 46) {
    } else {
      // console.log("A key was pressed", event.keyCode);
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    // cleanup this component
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const onClickRow = (record) => {
    return {
      onClick: () => {
        setRowId(record.Id);
      },
      onDoubleClick: () => {
        if (innerTable) {
          let obj = {};
          if (currentPage.forms?.find((f) => f.type == "document")) {
            const uniq = currentPage.forms?.find((f) => f.type == "document")
              .inputs[0].innerModal?.unique;
            if (uniq) {
              obj = { ...record };
            } else {
              if (
                currentPage.path == "/maxsulot-kirimi" ||
                currentPage.path == "/yuk-beruvchi-vozvrat"
              ) {
                var obj1 = {
                  kirim_som: record.valyuta_turi == 2 ? record.kirim_narxi : 0,
                  kirim_val: record.valyuta_turi == 1 ? record.kirim_narxi : 0,
                  sotish_som:
                    record.valyuta_turi == 2 ? record.sotish_narxi : 0,
                  sotish_val:
                    record.valyuta_turi == 1 ? record.sotish_narxi : 0,
                  zapis: false,
                };
              }
              obj = {
                ...record,
                ...obj1,
                Id: uuid(),
                hodim_id: record.Id,
              };
            }
          }

          addDocument(obj);
          setInnerModal(false);
        } else {
          if (!modal) {
            setRowId(record.Id);
            let obj = mainTableData.find((d) => d.Id == record.Id);
            setValues(obj);
            setModal(true);
          }
        }
      },
    };
  };
  const setRowClassName = (record) => {
    return record.Id === rowId ? "clickRowStyl" : "";
  };

  const tableFooter = () => {
    if ((currentPage.modalJami && modal && !innerTable) || summary) {
      return (
        <Table.Summary.Row>
          <Table.Summary.Cell>Жами:</Table.Summary.Cell>
          {/* {JamiClass(currentPage.modalJami, data).map((d) => (
            <Table.Summary.Cell style={{ textAlign: "right" }}>
              <Text>{d ? NumberFormat(d.toFixed(2)) : ""}</Text>
            </Table.Summary.Cell>
          ))} */}
        </Table.Summary.Row>
      );
    } else return null;
  };
  let filteredColumns = [];
  if (currentPage.filters) {
    filteredColumns = FilterColumns(currentPage.filters, columns, data);
  } else {
    filteredColumns = columns;
  }

  return (
    <>
      <div className={"main_table"}>
        <Table
          size="small"
          pagination={
            notPag
              ? false
              : { pageSizeOptions: ["10", "100", "1000", data.length] }
          }
          bordered
          rowClassName="table_row"
          columns={costumColumns ? costumColumns : filteredColumns}
          dataSource={data}
          loading={loading}
          scroll={{ y: 400 }}
          onRow={onClickRow}
          rowSelection={null}
          rowClassName={setRowClassName}
          summary={tableFooter}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    rowId: state.app.rowId,
    values: state.app.values,
    allData: state.app.allData,
    loading: state.app.loading,
    columns: state.app.currentPage.columns,
    currentPage: state.app.currentPage,
    modal: state.app.modal,
    mainTableData: state.app.mainTableData,
  };
};
export default connect(mapStateToProps, {
  setRowId,
  addDocument,
  setInnerModal,
  setValues,
  setModal,
})(MainTable);
