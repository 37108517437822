import Modal from "antd/lib/modal/Modal";
import { connect } from "react-redux";
  import {
    setInnerModal,
    setRowId,
    setValues,
    getInnerData,
    setInnerData,
  } from "../../Actions/AppActions";
  import "./Modal.css";
  import ModalTable from "../Tables/ModalTable/ModalTable";
  import { useEffect, useState } from "react";
  import SearchCom from "../Header/SearchCom";
  import { RiCloseCircleFill } from "react-icons/ri";

  const InnerModal = ({
    modal,
    setInnerModal,
    setRowId,
    allData,
    currentPage,
    getInnerData,
    innerData,
    setInnerData,
    loading,
    values,
  }) => {
    const [searchDataSource, setSearchDataSource] = useState([]);
    const [columns, setColumns] = useState([]);
    const [title, setTitle] = useState([]);
    const clearModal = () => {
      setInnerModal(false);
      setRowId(null);
    };

    useEffect(() => {
      if (currentPage.forms?.find(f => f.type == "document")) {
        const obj = currentPage.forms?.find(f => f.type == "document").inputs[0]
          .innerModal;
        setTitle(obj.title);
        setColumns(obj.columns);
        if (allData[obj.allData]) {
          setSearchDataSource(allData[obj.allData]);
        }
      }
    }, [currentPage]);

    useEffect(() => {
      if (modal && currentPage.innerData) {
        getInnerData([currentPage.innerData]);
      }
    }, [modal]);

    useEffect(() => {
      if (values.valyuta_turi && innerData.ostatka) {
        if (values.valyuta_turi == 1) {
          setInnerData({
            ostatka: innerData.ostatka.filter(d => d.valyuta_turi == 1),
          });
        } else {
          const doc = [...innerData.ostatka].map(d => {
            if (d.valyuta_turi == 1) {
              return {
                ...d,
                kirim_som: d.kirim_narxi * allData.constanta[0]?.kurs,
                sotish_som: d.sotish_narxi * allData.constanta[0]?.kurs,
              };
            } else return d;
          });
          setInnerData({
            ostatka: doc,
          });
        }
      }
    }, [loading]);
    useEffect(() => {
      if (modal && currentPage.innerData) {
        setSearchDataSource(innerData[currentPage.innerData]);
      }
    }, [innerData]);
    return (
      <Modal
        centered
        title={
          <div className={"modal_title"}>
            <span>{title}</span>
          </div>
        }
        visible={modal}
        footer={null}
        width="900px"
        onCancel={clearModal}
        closeIcon={<RiCloseCircleFill />}
      >
        <div
          style={{
            backgroundColor: "#eee",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <SearchCom setS={setSearchDataSource}></SearchCom>
          </div>
          <ModalTable
            innerTable={true}
            columns={columns}
            innerTableData={searchDataSource}
          ></ModalTable>
        </div>
      </Modal>
    );
  };

  const mapStateToProps = state => {
    return {
      modal: state.app.innerModal,
      values: state.app.values,
      allData: state.app.allData,
      currentPage: state.app.currentPage,
      innerData: state.app.innerData,
      loading: state.app.loading,
    };
  };

  export default connect(mapStateToProps, {
    setValues,
    getInnerData,
    setInnerModal,
    setInnerData,
    setRowId,
  })(InnerModal);
