import {COSTUM, DATE, PHONE, SELECT, STRING, TEXTAREA,} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import Zoom from "react-medium-image-zoom";
import {RiUser3Fill} from "react-icons/ri";
import {BaseUrl} from "../../BaseUrl";
import "react-medium-image-zoom/dist/styles.css";
import {SIMPLE} from "../../Component/Sidebar/PageTypes";
import {firmaTuri} from "../../Functions/Constanta";
import MapController from "../Components/MapController";
import MapTrigger from "../ServicePages/components/MapTrigger";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import moment from "moment";
import {Button} from "antd";

const YangiMijozlar = {
    title: "Yangi Mijozlar",
    path: "/yangimijozlar",
    icon: <RiUser3Fill/>,
    type: SIMPLE,
    filters: [
        {
            dataIndex: "time_create",
        },
        {
            dataIndex: "users"
        },

        // {
        //     dataIndex: "firma_turi",
        // },
        // {
        //     dataIndex: "faoliyati",
        // },
        // {
        //     dataIndex: "viloyatlar",
        // },
        // {
        //     dataIndex: "shaharlar",
        // },
    ],
    allData: [
        {value: "shahar_id", prop: "shaharlar", get: "nomi"},
        {value: "viloyat_id", prop: "viloyatlar", get: "nomi"},
        {value: "user_id", prop: "users", get: "fio"},
    ],
    columns: [
        {
            title: <div className="table_head">№</div>,
            render: (text, record, index) => index + 1,
            width: "50px",
            align: "center",
        },
        {
            title: <div className="table_head">FIO</div>,
            dataIndex: "fio",
            width: "180px",
        },
        {
            title: <div className="table_head">Nomi</div>,
            dataIndex: "nomi",
            width: "130px",
        },
        {
            title: <div className="table_head">Telefon</div>,
            dataIndex: "telefon",
            width: "150px",
        },
        {
            title: <div className="table_head">Izox</div>,
            dataIndex: "izox",
            // key: "firma_turi",
            width: "100px",
            // render: (text) => (text === 1 ? firmaTuri.optom : firmaTuri.chakana),
        },
        {
            title: <div className="table_head">Rasmlar</div>,
            dataIndex: "rasm",
            align: "center",
            // onFilter: (value, record) => record.faoliyati?.indexOf(value) === 0,
            width: "150px",
            render: (text, record) => {
                if (text) {
                    return (
                        <Zoom zoomMargin={40}>
                            <picture>
                                <source media="max-width: 200px" srcSet={BaseUrl + text}/>
                                <img src={BaseUrl + "/storage/" + text} alt="asda" height={30}/>
                                <img src={BaseUrl + "/storage/" + record.rasm2} height={30}/>
                                <img src={BaseUrl + "/storage/" + record.rasm3} height={30}/>
                            </picture>
                        </Zoom>
                    );
                } else return "";
            },
        },
        {
            title: <div className="table_head">lokatsiya</div>,
            align: "center",
            width: "100px",
            render: (text, record) => <MapTrigger record={record}/>,
        },
        {
            title: <div className="table_head">Vaqti</div>,
            dataIndex: "time_create",
            onFilter: (value, record) => {
                return moment(record.time_create).format("DD.MM.YYYY")?.indexOf(value) === 0;
            },
            render: (value) => <div>{moment(value).format("DD.MM.YYYY")}</div>,
            width: "150px",
            align: "center"
        },
        {
            title: <div className="table_head">Kim kiritdi</div>,
            dataIndex: "users",
            width: "200px",
            onFilter: (value, record) => record.user?.indexOf(value) === 0,
        },
        {
            title: <div className="table_head"></div>,
            width: "150px",
            align: "center",
            render: () => <div style={{display: "flex"}}>
                <Button type="primary"  style={{margin: "0 5px"}}><EditOutlined /></Button>
                <Button type="primary" danger style={{margin: "0 5px"}}><DeleteOutlined /></Button>
            </div>,
        },
    ],
    forms: [
        {
            grid: "1fr auto auto 1fr auto auto",
            inputs: [
                {
                    label: "Mijoz FIO",
                    name: "fio",
                    type: STRING,
                    required: true,
                },
                {
                    label: "Telefon",
                    name: "telefon",
                    type: PHONE,
                    required: true,
                },
                {
                    label: "Tug'ilgan sana",
                    name: "birth",
                    type: DATE,
                    required: true,
                },
                {
                    label: "Operator FIO",
                    name: "operator_fio",
                    type: STRING,
                },
                {
                    label: "Operator telefon raqami",
                    name: "operator_telefon",
                    type: PHONE,
                },
                {
                    label: "Operator tug'ilgan sana",
                    name: "operator_birth",
                    type: DATE,
                },
            ],
        },
        {
            grid: "1fr 1fr 1fr",
            inputs: [
                {
                    label: "Viloyat",
                    name: "viloyat_id",
                    type: SELECT,
                    required: true,
                    allData: {
                        type: "viloyatlar",
                        key: "Id",
                        text: "nomi",
                    },
                },
                {
                    label: "Shahar/Tuman",
                    name: "shahar_id",
                    type: SELECT,
                    required: true,
                    allData: {
                        type: "shaharlar",
                        key: "Id",
                        text: "nomi",
                        dependent: "viloyat_id",
                    },
                },
                {
                    label: "Manzili",
                    name: "manzil",
                    type: STRING,
                    required: true,
                },
            ],
        },
        {
            grid: "1fr 1fr 1fr",
            inputs: [
                {
                    label: "Firma nomi",
                    name: "firma_nomi",
                    type: STRING,
                    required: true,
                },
                {
                    label: "Firma turi",
                    name: "firma_turi",
                    type: SELECT,
                    costumData: [
                        {Id: 1, nomi: firmaTuri.optom},
                        {Id: 2, nomi: firmaTuri.chakana},
                        {Id: 3, nomi: firmaTuri.ishlabChiqarish},
                        {Id: 4, nomi: firmaTuri.xizmatKorsatish},
                    ],
                },
                {
                    label: "Faoliyati",
                    name: "faoliyati",
                    type: STRING,
                    required: true,
                },
            ],
        },
        // {
        //   grid: "1fr 1fr 1fr",
        //   inputs: [
        //     {
        //       label: "Kim orqali",
        //       name: "kim_orqali",
        //       type: SELECT,
        //       costumData: [
        //         { Id: 1, nomi: kimOrqali.MenedjerOrqali },
        //         { Id: 2, nomi: kimOrqali.MijozOrqali },
        //         { Id: 3, nomi: kimOrqali.AgentOrqali },
        //         { Id: 4, nomi: kimOrqali.HodimOrqali },
        //         { Id: 5, nomi: kimOrqali.ElonOrqali },
        //       ],
        //     },
        //     {
        //       label: "Hodim / User / Mijoz",
        //       name: "hodim_id",
        //       type: STRING,
        //       required: true,
        //     },
        //   ],
        // },
        {
            grid: "1fr 1fr",
            inputs: [
                {
                    label: "Qo'shimcha izox",
                    name: "izox",
                    type: TEXTAREA,
                },
                {
                    label: "",
                    name: "",
                    type: COSTUM,
                    component: <MapController></MapController>,
                },
            ],
        },
    ],
};
export default YangiMijozlar;
