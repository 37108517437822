import moment from "moment";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setDocument } from "../../../../Actions/AppActions";
import SanaFormat from "../../../../Functions/SanaFormat";

const SanaReturn = ({ time, setDocument, stateDetector }) => {
  useEffect(() => {
    if (!time) {
      setDocument({
        target: {
          name: "time_create",
          value: moment().format("YYYY-MM-DD HH:mm:ss"),
        },
      });
    }
  }, [stateDetector]);
  return <div>{SanaFormat(time)}</div>;
};
const mapStateToProps = (state) => {
  return {
    stateDetector: state.app.stateDetector,
  };
};
export default connect(mapStateToProps, { setDocument })(SanaReturn);
