export const pul = {
  valyuta: "Dollar",
  som: "So'm",
  perech: "Perechisleniya",
};

export const firmaTuri = {
  optom: "Optom savdo",
  chakana: "Chakana savdo",
  ishlabChiqarish: "Ishlab chiqarish",
  xizmatKorsatish: "Xizmat ko'rsatish",
};

export const kimOrqali = {
  AgentOrqali: "Agent orqali",
  MijozOrqali: "Mijoz orqali",
  MenedjerOrqali: "Menedjer orqali",
  HodimOrqali: "Hodim orqali",
  ElonOrqali: "E'lon orqali",
};

export const tolovTurlari = {
  1: "So'mdagi qarzga so'm",
  2: "Valyutadagi qarzga valyuta",
  3: "Perechisleniyadagi qarzga perechisleniya",
  4: "So'mdagi qarzga valyuta",
  5: "So'mdagi qarzga perechisleniya",
  6: "Valyutadagi qarzga so'm",
  7: "Valyutadagi qarzga perechisleniya",
  8: "Perechisleniyadagi qarzga so'm",
  9: "Perechisleniyadagi qarzga valyuta",
};
