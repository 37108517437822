import {
  CURRENCY,
  DATE,
  SELECT,
  STRING,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import { RiCloseCircleFill } from "react-icons/ri";
import "react-medium-image-zoom/dist/styles.css";
import { SIMPLE } from "../../Component/Sidebar/PageTypes";
const DasturlarRoyhati4 = {
  title: "Bekor qilingan dasturlar",
  path: "/dasturlar/4",
  icon: <RiCloseCircleFill color="red"></RiCloseCircleFill>,
  type: SIMPLE,
  filters: [
    {
      dataIndex: "mijozlar",
    },
  ],
  allData: [
    { value: "mijoz_id", prop: "mijozlar", get: "fio" },
    { value: "user_id", prop: "users", get: "fio" },
  ],
  columns: [
    {
      title: <div className="table_head">№</div>,
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">Mijoz FIO</div>,
      dataIndex: "mijozlar",
      onFilter: (value, record) => record.mijoz_id?.indexOf(value) === 0,
      width: "153px",
    },
    {
      title: <div className="table_head">Dastur nomi</div>,
      dataIndex: "dastur_nomi",
      width: "120px",
    },
    {
      title: <div className="table_head">Summa</div>,
      children: [
        {
          title: <div className="table_head">So'm</div>,
          dataIndex: "summa_som",
          width: "117px",
        },
        {
          title: <div className="table_head">Dollar</div>,
          dataIndex: "summa_val",
          width: "137px",
        },
        {
          title: <div className="table_head">Perech</div>,
          dataIndex: "summa_perech",
          width: "101px",
        },
      ],
    },
    {
      title: <div className="table_head">Izox</div>,
      dataIndex: "izox",
      width: "101px",
    },
    {
      title: <div className="table_head">Ish olingan vaqti</div>,
      dataIndex: "time_create",
      width: "101px",
    },
    {
      title: <div className="table_head">Boshlangan vaqti</div>,
      dataIndex: "boshlangan_sana",
      width: "101px",
    },
    {
      title: <div className="table_head">Topshirilgan vaqti</div>,
      dataIndex: "tugatilgan_sana",
      width: "101px",
    },
    {
      title: <div className="table_head">Topshirish vaqti</div>,
      dataIndex: "sana",
      width: "101px",
    },
    {
      title: <div className="table_head">User</div>,
      dataIndex: "users",
      width: "101px",
    },
  ],

  forms: [
    {
      grid: "1fr 1fr 1fr",
      inputs: [
        {
          label: "Mijoz FIO",
          name: "mijoz_id",
          type: SELECT,
          required: true,
          allData: {
            type: "mijozlar",
            key: "Id",
            text: "fio",
          },
        },
        {
          label: "Dastur nomi",
          name: "dastur_nomi",
          type: STRING,
          required: true,
        },
        {
          label: "Topshirish vaqti",
          name: "sana",
          type: DATE,
          required: true,
        },
      ],
    },
    {
      grid: "1fr 1fr 1fr",
      inputs: [
        {
          label: "Summa so'm",
          name: "summa_som",
          type: CURRENCY,
        },
        {
          label: "Summa dollar",
          name: "summa_val",
          type: CURRENCY,
        },
        {
          label: "Summa perech",
          name: "summa_perech",
          type: CURRENCY,
        },
      ],
    },

    {
      grid: "1fr",
      inputs: [
        {
          label: "Izox",
          name: "izox",
          type: STRING,
          required: true,
        },
      ],
    },
  ],
};
export default DasturlarRoyhati4;
