import { Button, message } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  changeAllDocument,
  getMainTableData,
  setLoading,
  setMainTableData,
  setValues,
} from "../../Actions/AppActions";
import fetchApi from "../../Functions/fetchApi";
import FetchApi from "../../Functions/fetchApi";
import ModalInputs from "./ModalInputs";

const ModalForm = ({
  values,
  clearModal,
  setLoading,
  modal,
  getMainTableData,
  currentPage,
  setValues,
  allData,
  secondaryModal,
  changeAllDocument,
  rowId,
  actualValues,
}) => {
  useEffect(() => {
    if (values.dastur_id && currentPage.path == "/hodimBiriktirish") {
      let dastur = allData.dasturlar.find((a) => a.Id == values.dastur_id);
      setValues({
        ...values,
        summa_som: dastur?.summa_som,
        summa_val: dastur?.summa_val,
        summa_perech: dastur?.summa_perech,
        dastur_sana: dastur?.sana,
      });
    }
  }, [values.dastur_id]);

  useEffect(() => {
    if (values.hodim_id && currentPage.path == "/OylikTolash") {
      let dastur = allData.xodim_qoldiq.find((a) => a.Id == values.hodim_id);
      setValues({
        ...values,
        qoldiq_som: dastur?.qoldiq_som,
        qoldiq_val: dastur?.qoldiq_val,
        qoldiq_perech: dastur?.qoldiq_perech,
      });
    }
  }, [values.hodim_id]);

  useEffect(() => {
    if (values.dastur_id && currentPage.path == "/dasturTolovi") {
      let dastur = allData.dastur_qoldiq.find((a) => a.Id == values.dastur_id);
      setValues({
        ...values,
        dastur_som: dastur?.dastur_som,
        dastur_val: dastur?.dastur_val,
        dastur_perech: dastur?.dastur_perech,
        qoldiq_som: dastur?.qoldiq_som,
        qoldiq_val: dastur?.qoldiq_val,
        qoldiq_perech: dastur?.qoldiq_perech,
      });
    }
  }, [values.dastur_id]);

  useEffect(() => {
    if (currentPage.path == "/mijoz-buyurtma") {
      const docs = values.document;
      let mapedDocs = [];
      if (allData.ostatka) {
        mapedDocs = docs?.map((d) => {
          let ost = allData.ostatka.find((a) => a.Id == d.element_id);
          if (ost) {
            return { ...d, qoldiq: ost.qoldiq };
          } else {
            return d;
          }
        });
        changeAllDocument(mapedDocs);
      }
    }
  }, [modal]);

  const fetchYukQarzi = async (id) => {
    setLoading(true);
    const res = await fetchApi("/yukberuvchi_qarzi", "GET", null, id);

    if (res && res.status == 200) {
      setValues({
        ...values,
        qarzi_som: res.data.length > 0 ? res.data[0].summa_som : 0,
        qarzi_val: res.data.length > 0 ? res.data[0].summa_val : 0,
      });
    }

    setLoading(false);
  };

  const fetchAgentQarzi = async (data) => {
    setLoading(true);
    const res = await fetchApi("/mijozqarzi", "POST", data);
    if (res && res.status == 200) {
      setValues({
        ...values,
        qarzi_som: res.data.length > 0 ? res.data[0].summa_som : 0,
        qarzi_val: res.data.length > 0 ? res.data[0].summa_val : 0,
      });
    }

    setLoading(false);
  };

  const { location } = useHistory();
  const [loadings, setLoadings] = useState(false);
  const key = 32432;
  const handleSubmit = () => {
    const requiredInputs = [];
    currentPage.forms.forEach((el) => {
      el.inputs.forEach((d) => {
        if (d.required) {
          requiredInputs.push(d);
        }
      });
    });
    let error = [];
    requiredInputs.forEach((d) => {
      if (!(values[d.name] && values[d.name] !== "")) {
        error.push(`${d.label} kiriting!`);
      }
    });
    if (error.length > 0) {
      message.error({ content: error[0], key: key });
    } else {
      RequestJson(
        secondaryModal.open ? secondaryModal.path : location.pathname,
        values,
        getMainTableData
      );
    }
  };

  const elementChangeDocument = () => {
    const document = actualValues.document;
    let newDocument = [];
    if (document) {
      newDocument = document.map((doc) => {
        if (doc.Id == rowId) {
          return {
            ...doc,
            ...values,
            Id: rowId,
            element_id: values.Id,
          };
        } else return doc;
      });

      changeAllDocument(newDocument);
    }
  };

  const RequestJson = async (url, data, refreshCallback) => {
    let user_id = null;
    if (
      JSON.parse(window.sessionStorage.getItem("user")) &&
      JSON.parse(window.sessionStorage.getItem("user")).Id
    ) {
      user_id = JSON.parse(window.sessionStorage.getItem("user")).Id;
    }
    setLoadings(true);
    const response = await FetchApi(url, "POST", { ...data, user_id: user_id });
    if (response && response.status === 200) {
      refreshCallback(url);
      elementChangeDocument();
      clearModal();
    }
    setLoadings(false);
  };

  const mijozBuyurtma = () => {
    if (currentPage.path == "/agent-buyurtma") {
      RequestJson("/agent-sotuv", values, getMainTableData);
    } else {
      RequestJson("/mijoz-sotuv", values, getMainTableData);
    }
  };
  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="form_container">
          {
            <ModalInputs
              forms={
                secondaryModal.open ? secondaryModal.forms : currentPage.forms
              }
            >
              {" "}
            </ModalInputs>
          }
        </div>
      </form>
      <div className="form_submit">
        <div
          style={{ display: "flex", justifyContent: "center", gridGap: "10px" }}
        >
          <Button className={"form-btn btn-back"} onClick={() => clearModal()}>
            Ортга
          </Button>
          <Button
            loading={loadings}
            className={"form-btn btn-submit"}
            onClick={handleSubmit}
          >
            Сақлаш
          </Button>
          {(currentPage.path == "/agent-buyurtma" ||
            currentPage.path == "/mijoz-buyurtma") && (
            <Button
              onClick={mijozBuyurtma}
              type="primary"
              danger
              className="form-btn"
            >
              Буюртмани қабул қилиш
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    values: state.app.secondaryModal.open
      ? state.app.values2
      : state.app.values,
    modal: state.app.modal,
    actualValues: state.app.values,
    mainTableData: state.app.mainTableData,
    rowId: state.app.rowId,
    currentPage: state.app.currentPage,
    allData: state.app.allData,
    secondaryModal: state.app.secondaryModal,
  };
};

export default connect(mapStateToProps, {
  setValues,
  setMainTableData,
  getMainTableData,
  setLoading,
  setValues,
  changeAllDocument,
})(ModalForm);
