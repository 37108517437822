import DasturTurlari from "./DasturTurlari";
import Hodimlar from "./Hodimlar";
import PulAyirboshlash from "./PulAyirboshlash";
import Shahar from "./Shahar";
import Users from "./Users";
import Viloyat from "./Viloyat";
import XarajatNomlari from "./XarajatNomlari";
import Yonalishlar from "./Yonalishlar";
export default () => {
  return [
    Viloyat,
    Shahar,
    XarajatNomlari,
    Yonalishlar,
    Hodimlar,
    Users,
    PulAyirboshlash,
    DasturTurlari,
  ];
};
