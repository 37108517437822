import { FaHome } from "react-icons/fa";
import { ASOSIY } from "../Component/Sidebar/PageTypes";
const AsosiyOyna = {
  title: "Asosiy Oyna",
  path: "/",
  icon: <FaHome></FaHome>,
  type: ASOSIY,
};

export default AsosiyOyna;
