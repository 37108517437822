import React from "react";
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import moment from "moment";
import {RiBattery2ChargeFill} from "react-icons/ri";

const position = [40.3801954, 71.7512621]
const AllMapContainer = ({data}) => {
    console.log(data)
    return (<MapContainer  center={position} zoom={13} scrollWheelZoom={false}>
        <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {data?.map((item, i) => (
            <Marker position={[item.lat, item.lang]}>
                <Popup>
                    {item.fio} нинг<br></br> {moment().format("DD.MM.YYYY HH:mm")}
                    <br></br>
                    пайтдаги жойлашуви <br></br>
                </Popup>
            </Marker>
        ))}
    </MapContainer>);
};

export default AllMapContainer;
