import React from "react";
import { connect } from "react-redux";
import { setModal2, setValues } from "../../Actions/AppActions";
import "../../Component/Modals/Modal.css";
import Modal from "antd/lib/modal/Modal";
import { RiCloseCircleFill } from "react-icons/ri";
import MapContainers from "./MapContainer";
function Modal2({ setModal2, modal2, setValues, values }) {
  const clearModal = () => {
    setModal2(false);
    setValues({});
  };

  return (
    <Modal
      centered
      maskClosable={false}
      keyboard={false}
      title={
        <div className="modal-title">
          {values.fio}нинг жойлашуви{" "}
          {values.lat ? null : (
            <span style={{ color: "red" }}>Мавжуд эмас!</span>
          )}
        </div>
      }
      onCancel={clearModal}
      width="80%"
      visible={modal2}
      footer={null}
      closeIcon={<RiCloseCircleFill />}
    >
      <MapContainers
        lat={values.lat ? values.lat : 40.360682}
        fio={values.fio}
        lng={values.lng ? values.lng : 71.783157}
      ></MapContainers>
    </Modal>
  );
}
const mapStateToProps = (state) => {
  return {
    modal2: state.app.modal2,
    values: state.app.values,
  };
};
export default connect(mapStateToProps, {
  setModal2,
  setValues,
})(Modal2);
