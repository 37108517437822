import {
  DATE,
  PHONE,
  STRING,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import { RiUser3Fill } from "react-icons/ri";
import "react-medium-image-zoom/dist/styles.css";
import { SIMPLE } from "../../Component/Sidebar/PageTypes";
const PulAyirboshlash = {
  title: "Pul ayirboshlash",
  path: "/servis/pulAyirboshlash",
  icon: <RiUser3Fill></RiUser3Fill>,
  type: SIMPLE,
  allData: [{ value: "user_id", prop: "users", get: "fio" }],
  columns: [
    {
      title: <div className="table_head">№</div>,
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">Vaqti</div>,
      dataIndex: "time_create",
      width: "70px",
    },
    {
      title: <div className="table_head">Izox</div>,
      dataIndex: "izox",
      width: "120px",
    },
    {
      title: <div className="table_head">Chiquvchi</div>,
      children: [
        {
          title: <div className="table_head">So'm</div>,
          dataIndex: "chiquvchi_som",
          width: "79px",
        },
        {
          title: <div className="table_head">Val</div>,
          dataIndex: "chiquvchi_val",
          width: "79px",
        },
        {
          title: <div className="table_head">Perech</div>,
          dataIndex: "chiquvchi_perech",
          width: "79px",
        },
      ],
    },
    {
      title: <div className="table_head">Kiruvchi</div>,
      children: [
        {
          title: <div className="table_head">So'm</div>,
          dataIndex: "kiruvchi_som",
          width: "79px",
        },
        {
          title: <div className="table_head">Val</div>,
          dataIndex: "kiruvchi_val",
          width: "79px",
        },
        {
          title: <div className="table_head">Perech</div>,
          dataIndex: "kiruvchi_perech",
          width: "79px",
        },
      ],
    },
    {
      title: <div className="table_head">User FIO</div>,
      dataIndex: "users",
      width: "80px",
    },
  ],

  forms: [
    {
      grid: "1fr 4fr",
      inputs: [
        {
          label: "Sana",
          name: "time_create",
          type: DATE,
          required: true,
        },
        {
          label: "Izox",
          name: "izox",
          type: STRING,
          required: true,
        },
      ],
    },
    {
      grid: "1fr 1fr 1fr",
      inputs: [
        {
          label: "Chiquvchi So'm",
          name: "chiquvchi_som",
          type: STRING,
          required: true,
        },
        {
          label: "Chiquvchi Val",
          name: "chiquvchi_val",
          type: STRING,
          required: true,
        },
        {
          label: "Chiquvchi perech",
          name: "chiquvchi_perech",
          type: STRING,
          required: true,
        },
      ],
    },
    {
      grid: "1fr 1fr 1fr",
      inputs: [
        {
          label: "Kiruvchi So'm",
          name: "kiruvchi_som",
          type: STRING,
          required: true,
        },
        {
          label: "Kiruvchi Val",
          name: "kiruvchi_val",
          type: STRING,
          required: true,
        },
        {
          label: "Kiruvchi perech",
          name: "kiruvchi_perech",
          type: STRING,
          required: true,
        },
      ],
    },
  ],
};
export default PulAyirboshlash;
