import {
  COSTUM,
  CURRENCY,
  DATE,
  MAPLATLNG,
  NUMBER,
  PHONE,
  SELECT,
  IMAGE,
  STRING,
  FILE,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import Zoom from "react-medium-image-zoom";
import { RiUser3Fill } from "react-icons/ri";
import { BaseUrl } from "../../BaseUrl";
import "react-medium-image-zoom/dist/styles.css";
import { SIMPLE } from "../../Component/Sidebar/PageTypes";
import { firmaTuri, kimOrqali, pul } from "../../Functions/Constanta";
import MapController from "../Components/MapController";
const Hodimlar = {
  title: "Hodimlar",
  path: "/servis/hodimlar",
  icon: <RiUser3Fill></RiUser3Fill>,
  type: SIMPLE,
  filters: [
    {
      dataIndex: "viloyatlar",
    },
    {
      dataIndex: "shaharlar",
    },
  ],
  allData: [
    { value: "shahar_id", prop: "shaharlar", get: "nomi" },
    { value: "viloyat_id", prop: "viloyatlar", get: "nomi" },
    { value: "yonalish_id", prop: "yonalishlar", get: "nomi" },
  ],
  columns: [
    {
      title: <div className="table_head">№</div>,
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">FIO</div>,
      dataIndex: "fio",
      width: "153px",
    },
    {
      title: <div className="table_head">Telefon</div>,
      dataIndex: "telefon",
      width: "120px",
    },
    {
      title: <div className="table_head">Viloyati</div>,
      dataIndex: "viloyatlar",
      onFilter: (value, record) => record.viloyat_id?.indexOf(value) === 0,
      width: "101px",
    },
    {
      title: <div className="table_head">Shahar/Tuman</div>,
      dataIndex: "shaharlar",
      onFilter: (value, record) => record.shahar_id?.indexOf(value) === 0,
      width: "101px",
    },
    {
      title: <div className="table_head">Manzili</div>,
      dataIndex: "manzil",
      width: "120px",
    },
    {
      title: <div className="table_head">Tug'ilgan sana</div>,
      dataIndex: "birth",
      width: "100px",
    },
    {
      title: <div className="table_head">Rasmi</div>,
      dataIndex: "image",
      width: "70px",
      render: (text) => {
        if (text) {
          return (
            <Zoom zoomMargin={40}>
              <picture>
                <source
                  media="max-width: 200px"
                  srcSet={BaseUrl + text.slice(1)}
                />
                <img src={BaseUrl + text.slice(1)} alt="asda" height={30} />
              </picture>
            </Zoom>
          );
        } else return "";
      },
    },
  ],

  forms: [
    {
      grid: "3fr 2fr 2fr 2fr",
      inputs: [
        {
          label: "Hodim FIO",
          name: "fio",
          type: STRING,
          required: true,
        },
        {
          label: "Telefon",
          name: "telefon",
          type: PHONE,
          required: true,
        },
        {
          label: "Viloyat",
          name: "viloyat_id",
          type: SELECT,
          required: true,
          allData: {
            type: "viloyatlar",
            key: "Id",
            text: "nomi",
          },
        },
        {
          label: "Shahar/Tuman",
          name: "shahar_id",
          type: SELECT,
          required: true,
          allData: {
            type: "shaharlar",
            key: "Id",
            text: "nomi",
            dependent: "viloyat_id",
          },
        },
      ],
    },
    {
      grid: "3fr 2fr 2fr",
      inputs: [
        {
          label: "Manzili",
          name: "manzil",
          type: STRING,
          required: true,
        },
        {
          label: "Tug'ilgan sana",
          name: "birth",
          type: DATE,
          required: true,
        },
        {
          label: "Yo'nalish",
          name: "yonalish_id",
          type: SELECT,
          required: true,
          allData: {
            type: "yonalishlar",
            key: "Id",
            text: "nomi",
          },
        },
      ],
    },
    {
      grid: "3fr 2fr 2fr",
      inputs: [
        {
          label: "Rasmi",
          name: "image",
          type: IMAGE,
        },
        {
          label: "Pasport FAYL",
          name: "pasport",
          type: FILE,
        },
        {
          label: "Oilasi FAYL",
          name: "family",
          type: FILE,
        },
      ],
    },
    {
      grid: "1fr",
      inputs: [
        {
          label: "",
          name: "",
          type: COSTUM,
          component: (
            <MapController lat={40.360682} lng={71.783157}></MapController>
          ),
        },
      ],
    },
  ],
};
export default Hodimlar;
