import MiqdorInput from "../../Component/Tables/ModalTable/KirimChiqimInputs/MiqdorInput";
import { RiStackFill } from "react-icons/ri";
import {
  DATE,
  LABEL2,
  MODALTABLE,
  SELECT,
  STRING,
  TEXTBLOCK,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import { SIMPLE } from "../../Component/Sidebar/PageTypes";
import MiqdorSelect from "../../Component/Tables/ModalTable/KirimChiqimInputs/MiqdorSelect";
import NumberFormat from "../../Functions/NumberFormat";
import SanaFormat from "../../Functions/SanaFormat";
import ShowAllBtn from "../Components/ShowAllBtn";
import IzohInput from "../../Component/Tables/ModalTable/KirimChiqimInputs/IzohInput";
import SanaReturn from "../../Component/Tables/ModalTable/KirimChiqimInputs/SanaReturn";
const HodimBiriktirish = {
  title: "Hodim biriktirish",
  path: "/hodimBiriktirish",
  type: SIMPLE,
  icon: <RiStackFill></RiStackFill>,
  allData: [
    { value: "user_id", prop: "users", get: "fio" },
    { value: "hodim_id", prop: "hodimlar", get: "fio" },
    { value: "dastur_id", prop: "dasturlar", get: "dastur_nomi" },
  ],
  innerData: "hodimlar",
  columns: [
    {
      title: <div className="table_head">№</div>,
      key: "index",
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">Sana</div>,
      dataIndex: "time_create",
      key: "nomi",
      width: "150px",
      render: (text) => SanaFormat(text),
    },
    {
      title: <div className="table_head">Dastur nomi</div>,
      dataIndex: "dasturlar",
      key: "nomi",
      width: "178px",
    },
    {
      title: <div className="table_head">User FIO</div>,
      dataIndex: "users",
      width: "180px",
    },
  ],
  forms: [
    {
      type: "document",
      grid: "1fr",
      actionInputs: [
        {
          grid: "1fr 2fr 1fr 1fr 1fr 1fr 2fr",
          inputs: [
            {
              label: "Sana",
              name: "time_create",
              type: DATE,
            },
            {
              label: "Dastur",
              name: "dastur_id",
              type: SELECT,
              required: true,
              allData: {
                type: "dasturlar",
                key: "Id",
                text: "dastur_nomi",
              },
            },
            {
              label: "Topshirish vaqti",
              name: "dastur_sana",
              type: LABEL2,
            },
            {
              label: "So'm",
              name: "summa_som",
              type: TEXTBLOCK,
            },
            {
              label: "Valyuta",
              name: "summa_val",
              type: TEXTBLOCK,
            },
            {
              label: "Perech",
              name: "summa_perech",
              type: TEXTBLOCK,
            },
            {
              label: "Izox",
              name: "izox",
              type: STRING,
            },
          ],
        },
      ],
      inputs: [
        {
          name: "document",
          type: MODALTABLE,
          innerModal: {
            title: "Hodimlar",
            columns: [
              {
                title: <div className="table_head">№</div>,
                key: "index",
                render: (text, record, index) => index + 1,
                width: "50px",
                align: "center",
              },
              {
                title: <div className="table_head">Hodim</div>,
                dataIndex: "fio",
                key: "fio",
              },
            ],
          },
          costumColumns: [
            {
              title: <div className="table_head">№</div>,
              key: "index",
              render: (text, record, index) => index + 1,
              width: "70px",
              align: "center",
            },
            {
              title: <div className="table_head">Hodim</div>,
              dataIndex: "hodim_id",
              key: "fio",
              width: "210px",
              render: (text) => (
                <MiqdorSelect
                  name={"hodim_id"}
                  value={text}
                  placeholder="Hodim tanlang"
                  cursor="1"
                  get="fio"
                  allDataType={"hodimlar"}
                ></MiqdorSelect>
              ),
            },
            {
              title: <div className="table_head">Summa so'm</div>,
              dataIndex: "summa_som",
              key: "nomi",
              render: (text) => (
                <MiqdorInput name="summa_som" value={text}></MiqdorInput>
              ),
              width: "100px",
            },
            {
              title: <div className="table_head">Summa val</div>,
              dataIndex: "summa_val",
              key: "nomi",
              render: (text) => (
                <MiqdorInput name="summa_val" value={text}></MiqdorInput>
              ),
              width: "100px",
            },

            {
              title: <div className="table_head">Boshlang'ich sana</div>,
              dataIndex: "bosh_sana",
              width: "120px",
              render: (text, record) => (
                <IzohInput name="bosh_sana" value={text}></IzohInput>
              ),
            },
            {
              title: <div className="table_head">Muddati</div>,
              dataIndex: "muddati",
              width: "100px",
              render: (text) => (
                <MiqdorInput name="muddati" value={text}></MiqdorInput>
              ),
            },
            {
              title: <div className="table_head">Izox</div>,
              dataIndex: "izox",
              width: "150px",
              render: (text) => (
                <IzohInput name="izox" value={text}></IzohInput>
              ),
            },
          ],
        },
      ],
    },
  ],
};

export default HodimBiriktirish;
