import {
  COSTUM,
  DATE,
  PHONE,
  SELECT,
  STRING,
  CURRENCY,
  TEXTBLOCK,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import { RiUser3Fill } from "react-icons/ri";
import { GiPayMoney } from "react-icons/gi";
import "react-medium-image-zoom/dist/styles.css";
import { SIMPLE } from "../../Component/Sidebar/PageTypes";
import { tolovTurlari } from "../../Functions/Constanta";
import DocumentClass from "../../Functions/DocumentClass";
import moment from "moment";
const Xarajat = {
  title: "Xarajatlar",
  path: "/xarajat",
  icon: <GiPayMoney></GiPayMoney>,
  type: SIMPLE,
  allData: [
    { value: "user_id", prop: "users", get: "fio" },
    { value: "xarajat_id", prop: "xarajat_nomlari", get: "nomi" },
  ],
  filters: [
    {
      dataIndex: "xarajat_nomlari",
    },
  ],
  columns: [
    {
      title: <div className="table_head">№</div>,
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">Vaqti</div>,
      dataIndex: "time_create",
      width: "70px",
      render: (text) => moment(text).format("DD.MM.YYYY HH:mm"),
    },
    {
      title: <div className="table_head">Xarajat nomi</div>,
      dataIndex: "xarajat_nomlari",
      onFilter: (value, record) => record.xarajat_nomlari?.indexOf(value) === 0,
      width: "120px",
    },
    {
      title: <div className="table_head">To'lov summasi</div>,
      children: [
        {
          title: <div className="table_head">So'm</div>,
          dataIndex: "summa_som",
          width: "79px",
        },
        {
          title: <div className="table_head">Dollar $</div>,
          dataIndex: "summa_val",
          width: "79px",
        },
        {
          title: <div className="table_head">Perech</div>,
          dataIndex: "summa_perech",
          width: "79px",
        },
      ],
    },
    {
      title: <div className="table_head">Izox</div>,
      dataIndex: "izox",
      width: "70px",
    },
    {
      title: <div className="table_head">User FIO</div>,
      dataIndex: "users",
      width: "80px",
    },
  ],

  forms: [
    {
      grid: "150px 250px 1fr 1fr 1fr",
      inputs: [
        {
          label: "Sana",
          name: "sana",
          type: DATE,
        },
        {
          label: "Xarajat nomi",
          name: "xarajat_id",
          type: SELECT,
          required: true,
          allData: {
            type: "xarajat_nomlari",
            key: "Id",
            text: "nomi",
          },
        },
        {
          label: "To'lov so'm",
          name: "summa_som",
          type: CURRENCY,
        },
        {
          label: "To'lov dollar $",
          name: "summa_val",
          type: CURRENCY,
        },
        {
          label: "To'lov perech",
          name: "summa_perech",
          type: CURRENCY,
        },
      ],
    },

    {
      grid: "1fr",
      inputs: [
        {
          label: "Izox",
          name: "izox",
          type: STRING,
        },
      ],
    },
  ],
};
export default Xarajat;
