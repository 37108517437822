import React from "react";
import { connect } from "react-redux";
import { setDocument } from "../../../../Actions/AppActions";
import { Select } from "antd";

const { Option } = Select;
const MiqdorInput = ({
  setDocument,
  name,
  value,
  options,
  placeholder,
  allData,
  allDataType,
}) => {
  function onSearch(val) {}
  return (
    <Select
      value={value}
      style={{ width: 200, overflow: "hidden" }}
      showSearch
      onSearch={onSearch}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      placeholder={placeholder}
      onChange={(v) => {
        let a = {
          target: {
            name: name,
            value: v,
          },
        };
        setDocument(a);
      }}
    >
      {allDataType
        ? allData[allDataType]?.map((d) => (
            <Option value={d.Id} key={d.Id}>
              {d.fio}
            </Option>
          ))
        : options.map((op) => (
            <Option value={op.value} key={op.value}>
              {op.text}
            </Option>
          ))}
    </Select>
  );
};
const mapStateToProps = (state) => {
  return {
    allData: state.app.allData,
  };
};
export default connect(mapStateToProps, { setDocument })(MiqdorInput);
