import { RiBriefcaseFill, RiVipDiamondFill } from "react-icons/ri";
import { HiDocumentReport } from "react-icons/hi";
import { HISOBOT, SERVICES } from "../Component/Sidebar/PageTypes";
import AsosiyOyna from "./AsosiyOyna";
import DasturlarRoyhati1 from "./Menular/DasturlarRoyhati1";
import DasturlarRoyhati2 from "./Menular/DasturlarRoyhati2";
import DasturlarRoyhati3 from "./Menular/DasturlarRoyhati3";
import DasturlarRoyhati4 from "./Menular/DasturlarRoyhati4";
import DasturTolovi from "./Menular/DasturTolovi";
import HodimBiriktirish from "./Menular/HodimBiriktirish";
import MijozlarRoyhati from "./Menular/MijozlarRoyhati";
import Xarajat from "./Menular/Xarajat";
import Ulushlar from "./Menular/Ulushlar";
import OylikTolash from "./Menular/OylikTolash";
import YangiMijozlar from "./Menular/YangiMijozlar";

const PagesClass = [
  AsosiyOyna,
  MijozlarRoyhati,
  YangiMijozlar,
  {
    title: "Dasturlar",
    key: "sub1",
    icon: <RiBriefcaseFill></RiBriefcaseFill>,
    submenu: [
      DasturlarRoyhati1,
      DasturlarRoyhati2,
      DasturlarRoyhati3,
      DasturlarRoyhati4,
    ],
  },
  HodimBiriktirish,
  DasturTolovi,
  Xarajat,
  OylikTolash,
  Ulushlar,
  // Hisobot,
  {
    title: "Servis",
    path: "/service",
    icon: <RiVipDiamondFill></RiVipDiamondFill>,
    type: SERVICES,
  },
  {
    title: "Xisobot",
    path: "/hisobot",
    icon: <HiDocumentReport></HiDocumentReport>,
    type: SERVICES,
  },
];

export default PagesClass;
