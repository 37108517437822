import React from "react";
import SimplePage from "./Pages/SimplePage";
import { ASOSIY, HISOBOT, SERVICES, SIMPLE } from "./PageTypes";
import loginBrand from "../../images/Logo.png";
import ServicesPage from "./Pages/ServicesPage";
import HisobotPage from "./Pages/HisobotPage";

const Page = ({ type, dataSource }) => {
  let page = "";
  switch (type) {
    case SIMPLE:
      page = <SimplePage searchDataSource={dataSource}></SimplePage>;
      break;
    case SERVICES:
      page = <ServicesPage></ServicesPage>;
      break;
    case ASOSIY:
      page = (
        <div>
          <center>
            <img
              width="200"
              style={{ alignContent: "center" }}
              src={loginBrand}
              alt="logo"
            />
          </center>
        </div>
      );
      break;
    case HISOBOT:
      page = <HisobotPage></HisobotPage>;
      break;
    default:
      break;
  }
  return page;
};

export default Page;
