import React, { useEffect } from "react";
import HtmlParser from "react-html-parser";
import { connect } from "react-redux";
import { setHisobot } from "../../../Actions/AppActions";
import ModalInputs from "../../Modals/ModalInputs";

const HisobotPage = ({ currentPage, hisobot, setHisobot }) => {
  useEffect(() => {
    setHisobot(null);
  }, [currentPage]);
  return (
    <>
      <div>
        <ModalInputs forms={currentPage.hisobotActions}></ModalInputs>
      </div>
      <div
        style={{
          marginTop: "10px",
          overflowX: "auto",
          overflowY: "calc(100vh - 220px)",
          maxHeight: "calc(100vh - 220px)",
        }}
      >
        {hisobot && hisobot.html ? HtmlParser(hisobot.html) : ""}
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    currentPage: state.app.currentPage,
    hisobot: state.app.hisobot,
  };
};
export default connect(mapStateToProps, { setHisobot })(HisobotPage);
