import {
  PHONE,
  STRING,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import { RiUser3Fill } from "react-icons/ri";
import "react-medium-image-zoom/dist/styles.css";
import { SIMPLE } from "../../Component/Sidebar/PageTypes";
const Users = {
  title: "Ta'sischilar",
  path: "/servis/users",
  icon: <RiUser3Fill></RiUser3Fill>,
  type: SIMPLE,
  columns: [
    {
      title: <div className="table_head">№</div>,
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">FIO</div>,
      dataIndex: "fio",
      width: "153px",
    },
    {
      title: <div className="table_head">Telefon</div>,
      dataIndex: "telefon",
      width: "120px",
    },
    {
      title: <div className="table_head">Parol</div>,
      dataIndex: "parol",
      width: "79px",
    },
  ],

  forms: [
    {
      grid: "3fr 2fr 2fr",
      inputs: [
        {
          label: "FIO",
          name: "fio",
          type: STRING,
          required: true,
        },
        {
          label: "Telefon",
          name: "telefon",
          type: PHONE,
          required: true,
        },
        {
          label: "Parol",
          name: "parol",
          type: STRING,
          required: true,
        },
      ],
    },
  ],
};
export default Users;
