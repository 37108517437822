import { Button } from "antd";
import React from "react";
import { RiPrinterFill } from "react-icons/ri";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

const PrintBtn = ({ hisobot }) => {
  const history = useHistory();
  const toPrint = () => {
    if (hisobot && hisobot.html) {
      history.push("/print-hisobot");
    }
  };
  return (
    <Button onClick={toPrint} type="primary" className="action_btn main-btn">
      <RiPrinterFill className="action_icon"></RiPrinterFill>
    </Button>
  );
};
const mapStateToProps = (state) => {
  return {
    hisobot: state.app.hisobot,
  };
};
export default connect(mapStateToProps)(PrintBtn);
