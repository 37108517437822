import React from "react";
import { connect } from "react-redux";
import { getHisobot } from "../../../Actions/AppActions";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Button, message } from "antd";
const Refresh = ({ loading, getHisobot, values, currentPage, group, text }) => {
  const { location } = useHistory();
  const history = useHistory();
  const handleRefresh = () => {
    if (currentPage.hisobotActions) {
      const obj = {
        sana: values.sana
          ? moment(values.sana).format("YYYY-MM-DD HH:mm:ss")
          : moment()
              .set("hour", 0)
              .set("minute", 1)
              .format("YYYY-MM-DD HH:mm:ss"),
        sana2: values.sana2
          ? moment(values.sana2).format("YYYY-MM-DD HH:mm:ss")
          : moment()
              .set("hour", 23)
              .set("minute", 59)
              .format("YYYY-MM-DD HH:mm:ss"),
        brend_id: values.brend_id ? values.brend_id : null,
        maxsulot_id: values.maxsulot_id ? values.maxsulot_id : null,
        mijoz_id: values.mijoz_id ? values.mijoz_id : null,
        agent_id: values.agent_id ? values.agent_id : null,
        kassa_turi: values.kassa_turi ? values.kassa_turi : null,
        yuk_beruvchi_id: values.yuk_beruvchi_id ? values.yuk_beruvchi_id : null,
        kassa_turlari: group ? 1 : 0,
        byAgents: group ? 1 : 0,
        mijozlar: values.mijozlar,
        id1: values.id1,
        id2: values.id2,
      };
      const key = 3243122;
      const requiredInputs = [];
      currentPage.hisobotActions.forEach((el) => {
        el.inputs.forEach((d) => {
          if (d.required) {
            requiredInputs.push(d);
          }
        });
      });
      let error = [];
      requiredInputs.forEach((d) => {
        if (!(obj[d.name] && obj[d.name] !== "")) {
          error.push(`${d.label} kiriting!`);
        }
      });
      if (error.length > 0) {
        message.error({ content: error[0], key: key });
      } else {
        getHisobot(location.pathname, obj);
      }
    } else {
      history.goBack();
    }
  };
  return (
    <>
      <>
        <Button loading={loading} onClick={handleRefresh} type="primary">
          {text}
        </Button>
      </>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.app.loading,
    values: state.app.values,
    currentPage: state.app.currentPage,
    allData: state.app.allData,
  };
};
export default connect(mapStateToProps, { getHisobot })(Refresh);
