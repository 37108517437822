import {
  Button,
  DatePicker,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Radio,
  Select,
} from "antd";
import { Option } from "antd/lib/mentions";
import React from "react";
import PhoneInput from "react-phone-input-2";
import ImageUpload from "./ImageUpload";
import FileUpload from "./FileUpload";
import {
  BUTTON_MIJOZ,
  COSTUM,
  CURRENCY,
  DATE,
  IMAGE,
  FILE,
  MAP,
  MAPLATLNG,
  MIJOZ_OTKAZ,
  MODALTABLE,
  NUMBER,
  PHONE,
  RADIO,
  SELECT,
  STRING,
  TEXTBLOCK,
  LABEL2,
  TEXTAREA,
} from "./ModalInputTypes";
import "./Input.css";
import { connect } from "react-redux";
import ModalTable from "../../Tables/ModalTable/ModalTable";
import moment from "moment";
import { changeAllDocument, setValues } from "../../../Actions/AppActions";
import { useState } from "react";
import fetchApi from "../../../Functions/fetchApi";
import CurrencyInput from "react-currency-input-field";
import NumberFormat from "../../../Functions/NumberFormat";
import MapContainer from "../../../Pages/Components/MapContainer";
import MapController from "../../../Pages/Components/MapController";
import TextArea from "antd/lib/input/TextArea";
const ModalInput = ({
  type,
  label,
  name,
  onChange,
  values,
  allData,
  allDataType,
  costumData,
  costumColumns,
  condition,
  changeAllDocument,
  component,
  readOnly,
  mainTableData,
  currentPage,
  loading2,
  setValues,
}) => {
  let input = null;
  function onSearch() {}
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const qabulQilish = () => {
    const docs = values.document;
    let mapedDocs = [];
    if (docs) {
      mapedDocs = docs.map((d) => {
        let sotish_soni = 0;
        if (d.qoldiq >= d.miqdor_soni) {
          sotish_soni = d.miqdor_soni;
        } else {
          sotish_soni = d.qoldiq;
        }
        return { ...d, sotish_soni: sotish_soni };
      });

      changeAllDocument(mapedDocs);
    }
  };

  const mijozOtkaz = async () => {
    setLoading(true);
    const res = await fetchApi("/mijoz-otkaz", "POST", {
      Id: values.Id,
    });
    if (res && res.status == 200) {
      message.success("Буюртма бекор қилинди");
    }
    setLoading(false);
    setVisible(false);
  };

  const onBlur = () => {
    if (currentPage.path == "/servis/maxsulot" && values.nomi) {
      if (
        mainTableData.find(
          (d) =>
            d.nomi.toLowerCase().replace(/\s+/g, "") ==
            values.nomi.toLowerCase().replace(/\s+/g, "")
        )
      ) {
        message.warning("Бундай товар мавжуд");
      }
    }
  };
  switch (type) {
    case STRING:
      input = (
        <Input
          name={name}
          onBlur={onBlur}
          value={values[name]}
          onChange={onChange}
        />
      );
      break;
    case TEXTAREA:
      input = (
        <TextArea
          name={name}
          onBlur={onBlur}
          value={values[name]}
          onChange={onChange}
          showCount
          style={{ height: "100%" }}
        />
      );
      break;
    case COSTUM:
      input = component;
      break;
    case NUMBER:
      input = (
        <InputNumber
          min={0}
          type="number"
          readOnly={readOnly ? readOnly : false}
          value={
            allDataType
              ? allData[allDataType]?.length > 0 &&
                allData[allDataType][0][name]
              : values[name]
          }
          onChange={(value) => {
            const v = {
              target: {
                name: name,
                value: value,
              },
            };
            onChange(v);
          }}
        />
      );
      break;
    case SELECT:
      input = (
        <Select
          defaultValue={label + "ni tanlang"}
          value={values[name]}
          showSearch
          allowClear={true}
          loading={loading2}
          onSearch={onSearch}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={(value) => {
            const v = {
              target: {
                name: name,
                value: value,
              },
            };
            onChange(v);
          }}
        >
          {costumData
            ? costumData.map((d) => (
                <Option key={d.Id} value={d.Id}>
                  {d.nomi}
                </Option>
              ))
            : allDataType.dependent
            ? allData[allDataType.type]
                ?.filter(
                  (d) =>
                    d[allDataType.dependent] == values[allDataType.dependent]
                )
                .map((m) => (
                  <Option key={m[allDataType.key]} value={m[allDataType.key]}>
                    {m[allDataType.text]}
                  </Option>
                ))
            : allData[allDataType.type]?.map((m) => (
                <Option key={m[allDataType.key]} value={m[allDataType.key]}>
                  {m[allDataType.text]}
                </Option>
              ))}
        </Select>
      );
      break;
    case PHONE:
      input = (
        <PhoneInput
          country={"uz"}
          inputStyle={{
            width: "100%",
            border: "1px solid #ccc",
            padding: "4px 11px",
            borderRadius: "var(--input-radius)",
            backgroundColor: "var(--input-bg)",
          }}
          specialLabel={false}
          disableDropdown={true}
          countryCodeEditable={false}
          value={values[name] ? values[name] : "+998"}
          areaCodes={{
            uz: ["+998"],
          }}
          masks={{ uz: "(..) ...-..-.." }}
          prefix="+"
          onChange={(phone) => {
            const e = {
              target: {
                name: name,
                value: phone,
              },
            };

            onChange(e);
          }}
        />
      );
      break;
    case IMAGE:
      input = <ImageUpload onImage={onChange}></ImageUpload>;
      break;
    case FILE:
      input = <FileUpload onImage={onChange} name={name}></FileUpload>;
      break;
    case RADIO:
      input = (
        <div style={{ marginTop: "5px" }}>
          <Radio.Group onChange={onChange} name="turi" value={values.turi}>
            {costumData.map((d) => (
              <Radio value={d.Id}>{d.nomi}</Radio>
            ))}
          </Radio.Group>
        </div>
      );
      break;
    case MODALTABLE:
      input = (
        <ModalTable innerTable={false} columns={costumColumns}></ModalTable>
      );
      break;
    case TEXTBLOCK:
      input = (
        <div
          style={{
            color: "red",
          }}
        >
          {NumberFormat(values[name], name == "qarzi_val" && 1)}
        </div>
      );
      break;
    case LABEL2:
      input = (
        <div
          style={{
            color: "blue",
            fontWeight: "600",
          }}
        >
          {values[name]}
        </div>
      );
      break;
    case BUTTON_MIJOZ:
      input = (
        <Button onClick={qabulQilish} type="primary">
          {name}
        </Button>
      );
      break;
    case MIJOZ_OTKAZ:
      input = (
        <Popconfirm
          title="Буюртма бекор қилинади!"
          visible={visible}
          onConfirm={mijozOtkaz}
          okButtonProps={{ loading: loading }}
          onCancel={() => setVisible(false)}
        >
          <Button
            danger
            type="primary"
            onClick={() => setVisible(true)}
            loading={loading}
          >
            {name}
          </Button>
        </Popconfirm>
      );
      break;
    case DATE:
      input = (
        <DatePicker
          value={values[name] ? moment(values[name]) : moment()}
          onChange={(v) => {
            const e = {
              target: {
                name: name,
                value: v,
              },
            };

            onChange(e);
          }}
        />
      );
      break;
    case MAP:
      input = <MapContainer></MapContainer>;
      break;

    case MAPLATLNG:
      input = (
        <MapController
          lat={values.lat ? values.lat : 40.360682}
          fio={values.fio}
          lng={values.lng ? values.lng : 71.783157}
        ></MapController>
      );
      break;

    case CURRENCY:
      input = (
        <CurrencyInput
          id="input-example"
          name={name}
          decimalSeparator="."
          readOnly={readOnly ? readOnly : false}
          className="currency_input"
          value={values[name] ? values[name] : ""}
          decimalsLimit={2}
          onValueChange={(value, name) => {
            const e = {
              target: {
                name: name,
                value: value,
              },
            };

            if (
              name == "summa_som" &&
              currentPage.path == "/dasturTolovi" &&
              values.kurs
            ) {
              values.summa_val = value / values.kurs;
            } else if (
              name == "summa_val" &&
              currentPage.path == "/dasturTolovi" &&
              values.kurs
            ) {
              values.summa_som = value * values.kurs;
            } else if (
              name == "summa_perech" &&
              currentPage.path == "/dasturTolovi" &&
              values.kurs
            ) {
              values.summa_val = value / values.kurs;
              values.summa_som = 0;
            }

            if (
              name == "kurs" &&
              currentPage.path == "/dasturTolovi" &&
              values.summa_som
            ) {
              values.summa_val = values.summa_som / value;
            } else if (
              name == "kurs" &&
              currentPage.path == "/dasturTolovi" &&
              values.summa_val
            ) {
              values.summa_som = values.summa_val * value;
            }

            onChange(e);
          }}
        />
      );
      break;
    default:
      break;
  }

  if (condition && values[condition.prop] != condition.value) {
    return null;
  } else {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {label ? (
          <lable className="label">{label}</lable>
        ) : (
          <lable className="label" style={{ visibility: "hidden" }}>
            _
          </lable>
        )}
        <div className="form_input">{input}</div>
      </div>
    );
  }
};
const mapStateToProps = (state) => {
  return {
    values: state.app.secondaryModal.open
      ? state.app.values2
      : state.app.values,
    allData: state.app.allData,
    mainTableData: state.app.mainTableData,
    currentPage: state.app.currentPage,
    loading2: state.app.loading,
  };
};
export default connect(mapStateToProps, { changeAllDocument, setValues })(
  ModalInput
);
