import {
  CURRENCY,
  DATE,
  RADIO,
  SELECT,
  STRING,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import { RiChatNewFill } from "react-icons/ri";
import "react-medium-image-zoom/dist/styles.css";
import { SIMPLE } from "../../Component/Sidebar/PageTypes";
import SanaFormat from "../../Functions/SanaFormat";
const DasturlarRoyhati1 = {
  title: "Yangi dasturlar",
  path: "/dasturlar/1",
  icon: <RiChatNewFill color="yellow"></RiChatNewFill>,
  type: SIMPLE,
  filters: [
    {
      dataIndex: "mijozlar",
    },
  ],
  allData: [
    { value: "mijoz_id", prop: "mijozlar", get: "fio" },
    { value: "dastur_id", prop: "dastur_turlari", get: "nomi" },
    { value: "user_id", prop: "users", get: "fio" },
  ],
  columns: [
    {
      title: <div className="table_head">№</div>,
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">Mijoz FIO</div>,
      dataIndex: "mijozlar",
      onFilter: (value, record) => record.mijozlar?.indexOf(value) === 0,
      width: "153px",
    },
    {
      title: <div className="table_head">Dastur nomi</div>,
      dataIndex: "dastur_nomi",
      width: "120px",
    },
    {
      title: <div className="table_head">Summa</div>,
      children: [
        {
          title: <div className="table_head">So'm</div>,
          dataIndex: "summa_som",
          width: "117px",
        },
        {
          title: <div className="table_head">Dollar</div>,
          dataIndex: "summa_val",
          width: "137px",
        },
        {
          title: <div className="table_head">Perech</div>,
          dataIndex: "summa_perech",
          width: "101px",
        },
      ],
    },
    {
      title: <div className="table_head">Izox</div>,
      dataIndex: "izox",
      width: "101px",
    },
    {
      title: <div className="table_head">Ish olingan vaqti</div>,
      dataIndex: "time_create",
      width: "110px",
      render: (text) => SanaFormat(text),
    },
    {
      title: <div className="table_head">Topshirish vaqti</div>,
      dataIndex: "sana",
      width: "110px",
      render: (text) => SanaFormat(text),
    },
    {
      title: <div className="table_head">User</div>,
      dataIndex: "users",
      width: "120px",
    },
  ],

  forms: [
    {
      grid: "1fr 1fr 1fr",
      inputs: [
        {
          label: "Mijoz FIO",
          name: "mijoz_id",
          type: SELECT,
          required: true,
          allData: {
            type: "mijozlar",
            key: "Id",
            text: "fio",
          },
        },
        {
          label: "Dastur nomi",
          name: "dastur_nomi",
          type: STRING,
          required: true,
        },
        {
          label: "Dastur turi",
          name: "dastur_id",
          type: SELECT,
          allData: {
            type: "dastur_turlari",
            key: "Id",
            text: "nomi",
          },
        },
      ],
    },
    {
      grid: "auto auto 1fr 1fr",
      inputs: [
        {
          label: "Qabul qilingan vaqti",
          name: "qabul_sana",
          type: DATE,
          required: true,
        },
        {
          label: "Topshirish vaqti",
          name: "sana",
          type: DATE,
          required: true,
        },
        {
          label: "To'lov turi",
          name: "tolov_turi",
          type: RADIO,
          required: true,
          costumData: [
            { Id: 1, nomi: "So'm" },
            { Id: 2, nomi: "Valyuta" },
            { Id: 3, nomi: "Perechisleniya" },
          ],
        },
        {
          label: "Summa",
          name: "summa",
          type: CURRENCY,
        },
      ],
    },

    {
      grid: "1fr",
      inputs: [
        {
          label: "Izox",
          name: "izox",
          type: STRING,
          required: true,
        },
      ],
    },
  ],
};
export default DasturlarRoyhati1;
