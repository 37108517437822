import {
  COSTUM,
  CURRENCY,
  DATE,
  IMAGE,
  MAPLATLNG,
  NUMBER,
  PHONE,
  SELECT,
  STRING,
  TEXTAREA,
} from "../../Component/Modals/ModalComponents/ModalInputTypes";
import Zoom from "react-medium-image-zoom";
import { RiUser3Fill } from "react-icons/ri";
import { BaseUrl } from "../../BaseUrl";
import "react-medium-image-zoom/dist/styles.css";
import { SIMPLE } from "../../Component/Sidebar/PageTypes";
import { firmaTuri, kimOrqali, pul } from "../../Functions/Constanta";
import MapController from "../Components/MapController";
import { Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
const MijozlarRoyhati = {
  title: "Mijozlar ro'yhati",
  path: "/mijozlar",
  icon: <RiUser3Fill></RiUser3Fill>,
  type: SIMPLE,
  filters: [
    {
      dataIndex: "firma_turi",
    },
    {
      dataIndex: "faoliyati",
    },
    {
      dataIndex: "viloyatlar",
    },
    {
      dataIndex: "shaharlar",
    },
  ],
  allData: [
    { value: "shahar_id", prop: "shaharlar", get: "nomi" },
    { value: "viloyat_id", prop: "viloyatlar", get: "nomi" },
    { value: "user_id", prop: "users", get: "fio" },
  ],
  columns: [
    {
      title: <div className="table_head">№</div>,
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">Izox</div>,
      dataIndex: "izox",
      width: "180px",
    },
    {
      title: <div className="table_head">FIO</div>,
      dataIndex: "fio",
      width: "180px",
    },
    {
      title: <div className="table_head">Telefon</div>,
      dataIndex: "telefon",
      width: "130px",
    },
    {
      title: <div className="table_head">Firma nomi</div>,
      dataIndex: "firma_nomi",
      onFilter: (value, record) => record.brendlar?.indexOf(value) === 0,
      width: "150px",
    },
    {
      title: <div className="table_head">Firma turi</div>,
      dataIndex: "firma_turi",
      key: "firma_turi",
      width: "120px",
      render: (text) => (text === 1 ? firmaTuri.optom : firmaTuri.chakana),
    },
    {
      title: <div className="table_head">Faoliyati</div>,
      dataIndex: "faoliyati",
      onFilter: (value, record) => record.faoliyati?.indexOf(value) === 0,
      width: "150px",
    },
    {
      title: <div className="table_head">Viloyati</div>,
      dataIndex: "viloyatlar",
      onFilter: (value, record) => record.viloyat_id?.indexOf(value) === 0,
      width: "130px",
    },
    {
      title: <div className="table_head">Shahar/Tuman</div>,
      dataIndex: "shaharlar",
      onFilter: (value, record) => record.shahar_id?.indexOf(value) === 0,
      width: "150px",
    },
    {
      title: <div className="table_head">Manzili</div>,
      dataIndex: "manzil",
      width: "200px",
    },
    {
      title: <div className="table_head">Tug'ilgan sana</div>,
      dataIndex: "birth",
      width: "100px",
    },
    {
      title: <div className="table_head">Operator FIO</div>,
      dataIndex: "operator_fio",
      width: "100px",
    },
    {
      title: <div className="table_head">Operator telefoni</div>,
      dataIndex: "operator_telefon",
      width: "100px",
    },
    {
      title: <div className="table_head">Kim qo'shgan FIO</div>,
      dataIndex: "users",
      width: "140px",
    },
  ],

  forms: [
    {
      grid: "1fr auto auto 1fr auto auto",
      inputs: [
        {
          label: "Mijoz FIO",
          name: "fio",
          type: STRING,
          required: true,
        },
        {
          label: "Telefon",
          name: "telefon",
          type: PHONE,
          required: true,
        },
        {
          label: "Tug'ilgan sana",
          name: "birth",
          type: DATE,
          required: true,
        },
        {
          label: "Operator FIO",
          name: "operator_fio",
          type: STRING,
        },
        {
          label: "Operator telefon raqami",
          name: "operator_telefon",
          type: PHONE,
        },
        {
          label: "Operator tug'ilgan sana",
          name: "operator_birth",
          type: DATE,
        },
      ],
    },
    {
      grid: "1fr 1fr 1fr",
      inputs: [
        {
          label: "Viloyat",
          name: "viloyat_id",
          type: SELECT,
          required: true,
          allData: {
            type: "viloyatlar",
            key: "Id",
            text: "nomi",
          },
        },
        {
          label: "Shahar/Tuman",
          name: "shahar_id",
          type: SELECT,
          required: true,
          allData: {
            type: "shaharlar",
            key: "Id",
            text: "nomi",
            dependent: "viloyat_id",
          },
        },
        {
          label: "Manzili",
          name: "manzil",
          type: STRING,
          required: true,
        },
      ],
    },
    {
      grid: "1fr 1fr 1fr",
      inputs: [
        {
          label: "Firma nomi",
          name: "firma_nomi",
          type: STRING,
          required: true,
        },
        {
          label: "Firma turi",
          name: "firma_turi",
          type: SELECT,
          costumData: [
            { Id: 1, nomi: firmaTuri.optom },
            { Id: 2, nomi: firmaTuri.chakana },
            { Id: 3, nomi: firmaTuri.ishlabChiqarish },
            { Id: 4, nomi: firmaTuri.xizmatKorsatish },
          ],
        },
        {
          label: "Faoliyati",
          name: "faoliyati",
          type: STRING,
          required: true,
        },
      ],
    },
    // {
    //   grid: "1fr 1fr 1fr",
    //   inputs: [
    //     {
    //       label: "Kim orqali",
    //       name: "kim_orqali",
    //       type: SELECT,
    //       costumData: [
    //         { Id: 1, nomi: kimOrqali.MenedjerOrqali },
    //         { Id: 2, nomi: kimOrqali.MijozOrqali },
    //         { Id: 3, nomi: kimOrqali.AgentOrqali },
    //         { Id: 4, nomi: kimOrqali.HodimOrqali },
    //         { Id: 5, nomi: kimOrqali.ElonOrqali },
    //       ],
    //     },
    //     {
    //       label: "Hodim / User / Mijoz",
    //       name: "hodim_id",
    //       type: STRING,
    //       required: true,
    //     },
    //   ],
    // },
    {
      grid: "1fr 1fr",
      inputs: [
        {
          label: "Qo'shimcha izox",
          name: "izox",
          type: TEXTAREA,
        },
        {
          label: "",
          name: "",
          type: COSTUM,
          component: <MapController></MapController>,
        },
      ],
    },
  ],
};
export default MijozlarRoyhati;
